<template>
  <Users />
</template>

<script>
import Users from "@/components/admin/Users.vue";

export default {
  name: "UsersView",
  components: {
    Users,
  },
};
</script>