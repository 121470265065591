<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
      />
      <v-spacer class="col-5" />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      :item-class="itemRowBackground"
      sort-by="name"
      class="elevation-1"
    >
      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-tooltip bottom :key="h.value">
          <template v-slot:activator="{ on }">
            <span v-on="on" :key="h.value">{{ h.text }}</span>
          </template>
          <span>{{ header.tooltip }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    errors: [],
    dialog: false,
    search: "",
    expanded: [],
    rules: {
      required: (value) => !!value || "Required.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
    },
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        tooltip: "Name",
      },
      {
        text: "Email",
        value: "email",
        tooltip: "Email",
      },
      {
        text: "Reported",
        value: "reported",
        align: "right",
        tooltip:
          "This user have reported this month. This is reset the 10th of each month",
      },
    ],
    editedItem: {
      id: "",
      name: "",
      email: "",
    },
    editedIndex: -1,
    defaultItem: {
      id: "",
      name: "",
      email: "",
    },
  }),
  computed: {
    ...mapGetters({
      users: "users/users",
    }),
  },
  methods: {
    itemRowBackground: function (item) {
      return item.reported == "Yes" ? "style-1" : "style-2";
    },
  },
};
</script>

<style>
.style-1 {
  background-color: rgb(228, 239, 235);
}
.style-2 {
  background-color: rgb(239, 228, 232);
}
</style>
